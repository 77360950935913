// =========================================================================================@@
// Last Updated Date: Apr 7, 2025
// Last Updated By: Steven Yuen
// Status Level: 1
// ===========================================================================================

import React, { useEffect, useContext, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, View } from 'oio-react'
import { useSurveyResults } from 'src/core/graphql/hooks'
import TVControlsOverlay from 'src/sites/kits/Object/components/TV/ControlsOverlay'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import style from './style.less'

const TVPolls = ({ returnUrl }) => {
   const { initiative } = useContext(InitiativeHierarchyContext)
   const { surveyResults, networkStatus } = useSurveyResults({ initiativeId: initiative.id }, {
      pollInterval: 1000
   })

   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
   const currentQuestion = initiative.appsSurveyFormElements[currentQuestionIndex]
   const totalNumQuestions = initiative.appsSurveyFormElements.length
   const isFirstQuestion = currentQuestionIndex === 0
   const isLastQuestion = currentQuestionIndex === totalNumQuestions - 1

   // For current Question
   const surveyOptions = currentQuestion?.options || []
   const answerStats = surveyResults
      .find(r => r.elementId === currentQuestion?.id)?.answerStats ?? []

   const totalVotesCast = answerStats.reduce((amount, result) => result.count + amount, 0)
   const manyAnswers = surveyOptions.length > 5

   const handleGoToPreviousQuestion = () => {
      setCurrentQuestionIndex((prev) => {
         // We check, just in case someone arrow keys the bejesus out of this
         if (prev > 0) {
            return prev - 1
         }

         return 0
      })
   }

   const handleGotoNextQuestion = () => {
      setCurrentQuestionIndex((prev) => {
         // We check, just in case someone arrow keys the bejesus out of this
         if (prev < totalNumQuestions - 1) {
            return prev + 1
         }

         return totalNumQuestions - 1
      })
   }

   // Arrow Key funtionality
   useEffect(() => {
      const down = (e) => {
         if (e.key === 'ArrowRight') {
            handleGotoNextQuestion()
         } else if (e.key === 'ArrowLeft') {
            handleGoToPreviousQuestion()
         }
      }

      document.addEventListener('keydown', down)
      return () => document.removeEventListener('keydown', down)
   }, [])

   // For testing purposes - SY
   // const [level, setLevel] = useState(0)
   // useEffect(() => {
   //    const interval = setInterval(() => {
   //       setLevel((prev) => prev + 0.01)
   //    }, 300)
   //    return () => clearInterval(interval)
   // }, [])

   if (networkStatus <= 2) {
      return null
   }

   return (
      <div
         // width="100%"
         // height="100%"
         // padding="10vh 180px 90px 90px"
         // backgroundColor="#21272d"
         // backgroundColor="#000"
         // paddingOLD="60px 30px[a-b] 78px 120px 0px 72px[c]
         // 120px 120px 0px 120px[d] 120px 150px 0px 150px[e]"
         className={classNames(
            'w-full h-full flex justify-center items-center bg-black',
            manyAnswers ? style.manyAnswers : 'default'
         )}
         style={{
            padding: totalNumQuestions > 1 ? '90px 180px 180px 180px' : '90px 180px 90px 180px'
         }}>
         <div key={currentQuestion?.id} className="w-full">
            <div
               className={classNames(style.questionLabel, 'w-full text-center pb-16 tracking-tight')}
               style={{ 'text-wrap': 'balance' }}>
               {currentQuestion?.label}
            </div>
            <div className="w-full flex justify-center gap-8">
               <div className="min-w-[30%]">
                  {surveyOptions.map((option) => {
                     return (
                        <div
                           key={option}
                           className={classNames(
                              'flex items-center text-white opacity-80 overflow-hidden',
                              manyAnswers
                                 ? 'h-[3.5vh] text-[2.8vh] leading-[3vh] mb-3'
                                 : 'justify-end text-right h-[10vh] text-[3vh] leading-[3.5vh] mb-3'
                           )}>
                           <div
                              className={classNames(
                                 // 'overflow-hidden',
                                 manyAnswers ? 'max-h-[3vh]' : 'max-h-[10vh]'
                              )}
                              style={{ 'text-wrap': 'balance' }}>
                              {option}
                           </div>
                        </div>
                     )
                  })}
               </div>
               <div className={classNames('flex-1', manyAnswers
                  ? 'min-w-[360px]'
                  : 'min-w-[50%]')}>
                  {surveyOptions.map((option) => {
                     const votes = answerStats.find(r => r.answer === option)
                     const votePercentage = votes?.count / totalVotesCast || 0

                     return (
                        <div
                           key={option}
                           className={classNames(
                              'relative w-full flex items-center text-[3vh] leading-[3.5vh] pr-[120px]',
                              manyAnswers ? 'h-[3.5vh] mb-3' : 'h-[10vh] mb-3'
                           )}>
                           <div
                              className="h-full bg-[#25b668] min-w-[6px] rounded-sm transition-all duration-1000"
                              style={{ width: `${Math.round((votePercentage) * 100)}%` }}
                           />
                           <div className="flex items-center justify-end absolute top-0 right-0 w-[120px] pl-4 h-full text-white text-right opacity-70">
                              {`${Math.round(votePercentage * 100)}%`}
                           </div>
                        </div>
                     )
                  })}
               </div>
            </div>
         </div>
         {/* <Grid key={currentQuestion?.id} spacing="8px">
            <GridCell colspan="12">
               <View
                  className={style.questionLabel}
                  paddingBottom="64px">
                  {currentQuestion?.label}
               </View>
            </GridCell>
            {surveyOptions.map((option) => {
               const votes = answerStats.find(r => r.answer === option)
               const votePercentage = (votes?.count / totalVotesCast) || 0

               return (
                  <React.Fragment key={option}>
                     <GridCell colspan={manyAnswers ? '9' : '5'}>
                        <div className={style.answerLabelContainer}>
                           <div className={style.answerLabel}>
                              {option}
                           </div>
                        </div>
                     </GridCell>
                     <GridCell colspan={manyAnswers ? '3' : '7'}>
                        <View position="relative" width="100%" padding="0px 60px 0px 0px">
                           <View
                              width={`${Math.round(votePercentage * 100)}%`}
                              className={style.bar}>
                              <div className={style.barPercentageLabel}>
                                 {`${Math.round(votePercentage * 100)}%`}
                              </div>
                           </View>
                        </View>
                     </GridCell>
                  </React.Fragment>
               )
            })}
         </Grid> */}
         <View
            position="absolute"
            bottom="0px"
            left="0px"
            right="0px"
            display="flex"
            justifyContent="center"
            width="100%"
            padding="64px 0px 64px 0px">
            {!isFirstQuestion && (
               <Button
                  onClick={handleGoToPreviousQuestion}
                  color="rgba(255,255,255,0.5)"
                  name="Previous Question"
                  rounded
                  outline
               />
            )}
            {!isFirstQuestion && <View flex="0 0 auto" width="16px" height="16px" />}
            {!isLastQuestion && (
               <Button
                  onClick={handleGotoNextQuestion}
                  color="rgba(255,255,255,0.5)"
                  name="Next Question"
                  outline
                  rounded
               />
            )}
         </View>
         <TVControlsOverlay
            returnUrl={returnUrl}
            title={initiative.name}
            subtitle="You are currently viewing the TV Polls Feed"
         />
      </div>
   )
}

TVPolls.propTypes = {
   returnUrl: PropTypes.string.isRequired
}

export default TVPolls
