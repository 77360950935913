// =========================================================================================@@
// Last Updated Date: Mar 21, 2023
// Last Updated By: Steven
// Status Level: 2
// ===========================================================================================

import React, { useContext } from 'react'
import { Modal } from 'oio-react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import EventCheckInDesk from 'src/sites/kits/Events/apps/CheckInDesk'
import EventSchedule from 'src/sites/kits/Events/apps/Schedule'
import ObjectJoin from 'src/sites/kits/Object/apps/Join'
import PageCreate from 'src/sites/kits/Object/apps/PageCreate'
import PollEditor from 'src/sites/kits/Object/apps/PollEditor'
import TVFeed from 'src/sites/kits/Object/apps/TV/Feed'
import TVPolls from 'src/sites/kits/Object/apps/TV/Polls'
import TVRemote from 'src/sites/kits/Object/apps/TV/Remote'
import ModalRoute from 'src/sites/kits/Utils/ModalRoute'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'
import NestedCustomPage from '../Nested/CustomPage'
import Files from '../Nested/Files'
import GroupEvents from '../Nested/GroupEvents'
import People from '../Nested/People'
import ObjectHome from './Home'
import TempObjectSettings from './SettingsRoutes'

const ObjectViewRoutes = ({ contentPaddingLeft, contentPaddingRight }) => {
   const history = useHistory()
   const match = useRouteMatch()
   const { initiative } = useContext(InitiativeHierarchyContext)

   const { appsSchedulePublished, currentUserCanEdit } = initiative
   const currentUserCanViewSchedule = appsSchedulePublished || currentUserCanEdit

   return (
      <Switch>
         <Route
            exact
            path={`${match.path}`}
            render={() => (
               <ObjectHome
                  paddingLeft={contentPaddingLeft}
                  paddingRight={contentPaddingRight}
               />
            )}
         />
         <Route
            path={`${match.path}/-/events`}
            render={() => (
               <GroupEvents
                  paddingLeft={contentPaddingLeft}
                  paddingRight={contentPaddingRight}
               />
            )}
         />
         {/* TODO: Deprecate - see #866 */}
         <Route
            path={`${match.path}/-/files`}
            render={() => (
               <Files />
            )}
         />
         <Route
            path={`${match.path}/-/join`}
            render={() => (
               <>
                  <ObjectJoin onClose={() => history.push(match.url)} />
                  <ObjectHome />
               </>
            )}
         />
         <Route
            path={`${match.path}/-/people`}
            render={() => (
               <People
                  paddingLeft={contentPaddingLeft}
                  paddingRight={contentPaddingRight}
               />
            )}
         />
         <Route
            path={`${match.path}/-/pages/create`}
            render={() => (
               <PageCreate
                  onCancelButtonClick={() => history.push(match.url)}
                  onCreate={page => history.push(`${match.url}/${page.slug}`)}
                  paddingLeft={contentPaddingLeft}
                  paddingRight={contentPaddingRight}
               />
            )}
         />
         <Route
            path={`${match.path}/:pageSlug([a-zA-Z0-9][^/]+)`}
            render={() => (
               <NestedCustomPage
                  paddingLeft={contentPaddingLeft}
                  paddingRight={contentPaddingRight}
               />
            )}
         />
         {initiative.class === 'event' && currentUserCanViewSchedule && (
            <Route
               path={`${match.path}/-/schedule/:selectedDateKey?`}
               render={() => (
                  <EventSchedule
                     paddingLeft={contentPaddingLeft}
                     paddingRight={contentPaddingRight}
                  />
               )}
            />
         )}
         {initiative.class === 'event' && initiative.currentUserCanEdit && (
            <ModalRoute path={`${match.path}/-/checkin`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(0,0,0,0.85)"
                     width="100%"
                     height="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}>
                     <EventCheckInDesk returnUrl={match.url} />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {['discussion', 'event', 'session'].includes(initiative.class) && initiative.currentUserCanModerateMessages && (
            <ModalRoute path={`${match.path}/-/poll-editor`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(0,0,0,0.85)"
                     width="100%"
                     maxWidth="1200px"
                     height="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     zIndex="var(--tvViewZindex)">
                     <PollEditor onCloseButtonClick={onCloseTrigger} />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {['event', 'session'].includes(initiative.class) && initiative.currentUserCanModerateMessages && (
            <ModalRoute path={`${match.path}/-/tv-feed`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(0,0,0,0.85)"
                     width="100%"
                     height="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     zIndex="var(--tvViewZindex)">
                     <TVFeed returnUrl={match.url} />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {['event', 'session', 'discussion'].includes(initiative.class) && initiative.currentUserCanModerateMessages && (
            <ModalRoute path={`${match.path}/-/tv-polls`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(0,0,0,0.85)"
                     width="100%"
                     height="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     zIndex="var(--tvViewZindex)">
                     <TVPolls returnUrl={match.url} />
                  </Modal>
               )}
            </ModalRoute>
         )}
         {['event', 'session'].includes(initiative.class) && initiative.currentUserCanModerateMessages && (
            <ModalRoute path={`${match.path}/-/tv-remote`}>
               {({ open, onCloseComplete, onCloseTrigger }) => (
                  <Modal
                     borderRadius="6px"
                     overlayBackgroundColor="rgba(0,0,0,0.85)"
                     width="100%"
                     height="100%"
                     onCloseComplete={onCloseComplete}
                     onCloseTrigger={onCloseTrigger}
                     open={open}
                     zIndex="var(--tvViewZindex)">
                     <TVRemote returnUrl={match.url} />
                  </Modal>
               )}
            </ModalRoute>
         )}
         <TempObjectSettings />
      </Switch>
   )
}

ObjectViewRoutes.propTypes = {
   contentPaddingLeft: PropTypes.string.isRequired,
   contentPaddingRight: PropTypes.string.isRequired
}

export default ObjectViewRoutes
